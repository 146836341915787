// hello_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "output" ]
  static values = { total: Number}

  connect(){
      this.changed
  }
 
  changed(){
    let amount_paid = this.inputTarget.value
    let total_value = this.totalValue
    let amount_due = Math.round((amount_paid-total_value)*100)/100
    if (amount_due > 0) {
        this.outputTarget.textContent = `Change $${amount_due}`
    } else {
        this.outputTarget.textContent = ''
    }
  }

}