import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["snowboardAdvance","skiAdvance", "lastRecord"]

   selected(event) {
    console.log(event.target.selectedOptions[0].value)
    this.hideFields()
    switch (event.target.selectedOptions[0].value) {
      case 'Snowboard':
        this.snowboardAdvanceTarget.classList.remove('hidden')
        break;
      case 'Skis':
        this.skiAdvanceTarget.classList.remove('hidden')
        break;
    }
  }

  hideFields() {
    this.snowboardAdvanceTarget.classList.add('hidden')
    this.skiAdvanceTarget.classList.add('hidden')
    this.lastRecordTarget.classList.add('hidden')
  }
}